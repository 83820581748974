<template>
    <div>{{ period }}</div>
    <div>
        <div class="profile">
            <div class="image" :style="[`background-image: url('${userImage}')`]"></div>
            <div class="displayName"><a href="" @click.prevent>{{ userName }}</a></div>
        </div>
    </div>
    <div>{{ resources }}</div>
    <div class="income"><strong>+ ${{ CONVERT_PRICE(item.salary.toFixed(2)) }}</strong></div>
    <div><DropDownList @click.prevent class="salary-status" :class="[item.status]" @select="statusChange" v-model="item.status" position="right" :options="SALARY_STATUS_LIST" selectAttribute="value" showAttribute="name"/></div>
</template>

<script>
import moment from 'moment';

    export default {
        props: ['item'],
        emits: ['statusChange'],
        methods: {
            statusChange(status) {
                this.$emit('statusChange', status.value);
            }
        },
        computed: {
            userImage() {
                return this.item.user.image ? this.item.user.image : this.DEFAULTS.USER.IMAGE;
            },
            userName() {
                return this.item.user.name + ' ' + this.item.user.lastname + ' (' + this.item.user.displayName + ')';
            },
            period() {
                let start = moment.utc(this.item.period.start).format("ddd D MMM");
                let end = moment.utc(this.item.period.end).format("ddd D MMM");
                return `${start} - ${end}`;
            },
            resources() {
                let list = Object.values(this.item.resources).map(item => {
                    return item.name;
                });
                return list.join(', ');
            }
        }
    }
</script>

<style lang="scss" scoped>
.income {
    color: $success;
}

.profile {
    display: grid;
    align-items: center;
    grid-template-columns: 34px minmax(0, auto);
    column-gap: 15px;
    .image {
        width: 100%;
        padding-top: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 9px;
    }
}


.salary-status {
    height: 28px;
    padding: 0 10px;
    border-radius: 14px;
    transition: ease 0.3s;
    &:after {
        content: none !important;
    }
    &.pending {
        border: 1px solid $borderColor;
        background: $themeColor1;
        color: $textShade;
    }
    &.created {
        border: 1px solid $themeColor2;
        background: $themeColor2;
        color: #fff;
    }
    &.paid {
        border: 1px solid $success;
        background: $success;
        color: #fff;
    }
}
</style>