<template>
    <div class="new-report-wrapper">

        <div class="grid gap-15">
            <InputDropDownDatepicker v-model="item.date" :error="errors.date" placeholder="Select date" name="Report date" :description="reportDatesString ? reportDatesString : 'Select report date'"/>
            <div v-if="reportsExist" class="reports-exist">Salary report has already been generated for selected week. If you continue it will be overwritten.</div>
        </div>

    </div>
</template>

<script>
import moment from 'moment';
    export default {
        props: ['modal'],
        data() {
            return {
                item: {},
                errors: {},
                reportsExist: false,
            }
        },
        computed: {
            reportDates() {
                if(!this.item.date) return null;
                let itemDate = moment(new Date(this.item.date)).utc();
                let date = itemDate.clone().startOf('isoWeek');

                let query = {
                    start_date: date.toISOString(),
                    end_date: date.clone().endOf('isoWeek').toISOString()
                }

                return query;
            },
            reportDatesString() {
                if(!this.reportDates) return;
                let date1 = moment.utc(this.reportDates.start_date).format("ddd D MMM");
                let date2 = moment.utc(this.reportDates.end_date).format("ddd D MMM");
                return 'Report dates: ' + date1 + ' - ' + date2;
            }
        },
        watch: {
            "item.date"() {
                this.getSalaryReports();
            }
        },
        methods: {
            getSalaryReports() {
                this.reportsExist = false;
                if(!this.reportDates) return;

                let query = new URLSearchParams(this.reportDates).toString();

                this.ajax('GetReportFromSelectedDate', {
                    url: '/salary/all?' + query,
                    method: 'GET',
                }, (err, body) => {
                    if(!err && body.count > 0) this.reportsExist = true;
                });
            },
            async createReport() {
                this.modal.processing(true);
                await this.ajax('GetReportFromSelectedDate', {
                    url: '/salary/all',
                    method: 'post',
                    data: this.item
                }, (err, body) => {
                    if(err) {
                        this.$ShowAlert(body.message || this.TEXT_ERRORS['SOMETHING_WRONG']);
                        return;
                    }
                    this.$router.push({ query: this.reportDates })
                    this.modal.close();
                });
                this.modal.processing(false);
            }
        },
        mounted() {
            this.modal.onConfirm(() => {
                this.createReport();
            });
        }
    }
</script>

<style lang="scss" scoped>
.new-report-wrapper {
    padding: 20px;
}

.reports-exist {
    color: $error;
    font-size: 12px;
}
</style>