<template>
    <div class="wrapper grid row-50">
        
        <ActionBar title="Salary Report">
            <Button text="Create New Report" size="small" @click="createReport"/>
        </ActionBar>


        <div class="grid gap-20">
            <h3>Report period</h3>
            <PeriodPicker v-model="_pageManager.query.date"/>

            <div class="page-filters">
                <div class="filter-item">
                    <div class="filter-item-name">Status</div>
                    <DropDownList v-model="_pageManager.query.status" placeholder="All" selectAttribute="value" showAttribute="name" :options="[{ value: null, name: 'All' }, { value: 'paid', name: 'Paid' }, { value: 'created', name: 'Created' }, { value: 'pending', name: 'Pending' }]"/>
                </div>
            </div>

            <div class="item-list">
                <TableView :head="tableHead" :size="columnsSizes" v-if="(_pageManager.list.length > 0)" :loading="isLoading(['GetSalaryReports'])">
                    <!-- <router-link :to="`/salary/${item._id}`" style="color: inherit"> -->
                        <TableRow :size="columnsSizes"  :key="key" v-for="(item, key) of _pageManager.list">
                            <UserSalaryItem :item="item" @statusChange="(status) => { changeSalaryStatus(item._id, status) }"/>
                        </TableRow>
                    <!-- </router-link> -->
                </TableView>

                <div v-else>
                    <div v-if="isLoading(['GetSalaryReports'])" class="spinner-flex"><Spinner/></div>
                    <div v-else><NoDataMessage text="Nothing found"/></div>
                </div>
            </div>

            <div class="pagination">
                <Pagination :manager="_pageManager" @pageChange="(page) => { _pageManager.query.page = page }"/>
            </div>
            
        </div>
        
    </div>
</template>

<script>
import PaginationJS from '../../mixins/Pagination'
import Pagination from '../../mixins/Pagination/components/Pagination.vue'
import UserSalaryItem from '../../components/Salary/UserSalaryItem.vue'

import NewReport from '../../components/Salary/NewReport.vue'

import moment from 'moment'

    export default {
        mixins: [PaginationJS],
        components: {
            Pagination,
            UserSalaryItem
        },
        data() {
            return {
                tableHead: [
                    { name: 'Period' },
                    { name: 'User' },
                    { name: 'Source list' },
                    { name: 'Income' },
                    { name: 'Status' },
                ],
                columnsSizes: [ 'minmax(200px, 250px)', 'minmax(200px, 1fr)', 'minmax(200px, 1fr)', 'minmax(100px, 120px)', 'minmax(120px, 175px)'],
                timeout: null,
                selectedReportPeriod: null
            }
        },
        computed: {
            pageQuery() {
                return this.$route.query;
            },
            pageQueryFormatted() {
                let query = JSON.parse(JSON.stringify(this._pageManager.query));
                if(query.date && query.date.start) {
                    query.start_date = moment.utc(query.date.start).startOf("day").toISOString();
                }
                if(query.date && query.date.end) {
                    query.end_date = moment.utc(query.date.end).endOf("day").toISOString();
                }
                delete query.date;

                for(const item of Object.keys(query)) {
                    if(!query[item]) delete query[item];
                }

                return query;
            },
            reversePageQueryFormatted() {
                let query = JSON.parse(JSON.stringify(this.pageQuery));
                if(query.start_date && query.end_date) {
                    let dateObj = {
                        start: query.start_date,
                        end: query.end_date,
                    }
                    query.date = dateObj;
                }
                return query;
            }
        },
        watch: {
            reversePageQueryFormatted(to, from) {
                this._pageManager.query = this.reversePageQueryFormatted;
            },
            pageQueryFormatted(newVal, oldVal) {
                let q1 = JSON.stringify(newVal);
                let q2 = JSON.stringify(oldVal);
                if(q1 === q2) return;
                this.$router.push({ query: JSON.parse(JSON.stringify(this.pageQueryFormatted)) });
                this.getSalaryReports();
            }
        },
        methods: {
            changeSalaryStatus(salaryId, status) {
                this.ajax('ChangeSalaryStatus', {
                    url: `/salary/${salaryId}/status/${status}`,
                    method: 'PUT',
                }, (err, body) => {
                    if(err) {
                        this.$ShowAlert(body.message || this.TEXT_ERRORS['SOMETHING_WRONG']);
                    }
                });
            },
            getSalaryReports() {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {

                    let query = new URLSearchParams(this.pageQueryFormatted).toString();

                    this.ajax('GetSalaryReports', {
                        url: '/salary/all?' + query,
                        method: 'GET',
                    }, (err, body) => {
                        if(!err) {
                            this._pageManager.pages = body.pages;
                            this._pageManager.count = body.count;
                            this._pageManager.list = body.list;
                            this._pageManager.page = body.page;
                            this._pageManager.hasNextPage = body.hasNextPage;
                            this._pageManager.hasPrevPage = body.hasPrevPage;
                        }
                    });

                }, 150);
                
            },
            createReport() {
                let modal = this.$ShowModal({
                    title: 'Create New Report',
                    description: 'Select date for a new report and press start',
                    component: NewReport,
                    confirm: 'Start'
                });
            }
        },
        mounted() {
            this._pageManager.query = this.reversePageQueryFormatted;
        }
    }
</script>

<style lang="scss" scoped>
.wrapper {
    max-width: 1300px;
    margin: 0 auto;
}


.item-list {
    border-bottom: 1px solid $borderColor;
    padding: 0 0 20px 0;
}

.page-filters {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
}
</style>